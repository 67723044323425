$WEBPACK_ENV: dev;
.mobile-app {
  display: none;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 136px;
  width: 360px;
  max-width: 100%;
  background-color: #ffffff;
  box-shadow: 8px -2px 16px rgba(144, 152, 161, 0.05);
  border-radius: 8px 8px 0 0;
  padding: 16px;
  z-index: 10000000;

  &--show {
    display: block;
  }

  &-close {
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 13px;
    height: 13px;
    cursor: pointer;
  }

  &__top {
    display: flex;
    width: 100%;
    align-items: start;
  }

  &__logo {
    flex-shrink: 0;
    margin-right: 16px;
  }

  &__title {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-size: 11px;
    line-height: 1.45;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    padding: 0 15px;
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #540575;
    color: #fff;
    background-color: #540575;
    border-radius: 8px;
  }
}