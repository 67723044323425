$WEBPACK_ENV: dev;
@import "../../../abstracts/vars";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.modal-slider {
    &__close {
        display: inline-flex;
        text-align: right;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        align-self: flex-end;

        img {
            max-width: 100%;
            width: 100%;
            height: 100%;
        }
    }

    &__slide:not(:first-child) {
        display: none;
    }

    &__slides {
        padding: 0 4rem;
    }

    //&__slides_shown {
    //    &__slide:not(:first-child) {
    //        display: block !important;
    //    }
    //}

    &__btn.slick-arrow {
        top: 50%;
        transform: translateY(-50%) !important;
        background-image: url(#{$assetsPath}img/icons/arrow-pink.svg);
        display: inline-flex;
        position: absolute;
        bottom: 0;
        width: 3rem;
        height: 3rem;
        border: 1px solid #540575;
        border-radius: 4px;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }

    &__btn--prev.slick-arrow {
        left: 0;
    }

    &__btn--next.slick-arrow {
        right: 0;
        transform: translateY(-50%) rotate(180deg) !important;
    }

    &__btn.slick-arrow.slick-disabled {
        border-color: #0C1014;
        background-image: url(#{$assetsPath}img/icons/arrow-black.svg);
    }

    &__btn {
        &:active, &:focus {
            outline: none;
        }
    }
}

.slick-slide {
    overflow: hidden;
}

.online-marathon-slide {
    &__plate {
        margin: 0 5px 2.5rem 10px;
        padding: 1.25rem 2.18rem 1.25rem 5rem;
        min-height: 5.625rem;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.03), 0 20px 25px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            margin: 10px 10px 20px 10px;
        }
    }

    &__img {
        position: absolute;
        bottom: -2px;
        left: -2px;
        max-width: 100%;
        width: 70px;
        height: 70px;

        @include media-breakpoint-down(md) {
            width: 50px;
            height: 50px;
            bottom: -1px;
            left: -1px;
        }
    }

    &__btn {
        display: flex;
        margin: 0 auto;
        width: 100%;
        max-width: 16.25rem;
    }
}