$WEBPACK_ENV: dev;
@import "../../../abstracts/vars";
@import "../../../blocks/colors";

.cookies-consent-modal {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 73%;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 24px;
  padding: 40px 48px;
  z-index: 1000;

  &--show {
    display: flex;
  }

  @media screen and (max-width: 991px) {
    top: 87%;
    padding: 30px 38px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    top: 82%;
    text-align: center;
    border-radius: 10px;
    padding: 15px;
  }

  @media screen and (max-width: 480px) {
    padding: 20px 28px;
  }

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 991px) {
      line-height: 18px;
      font-size: 14px;
    }

    @media screen and (max-width: 480px) {
      line-height: 16px;
      font-size: 12px;
    }
  }

  &__accept {
    padding: 14px 57px;
    background-color: #FFFFFF;
    border: 1px solid $primary;
    border-radius: 8px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-left: 24px;
    max-height: 48px;
    outline: none !important;
    width: fit-content;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: $primary;
      color: #FFFFFF;
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 16px;
    }

    @media screen and (max-width: 480px) {
      padding: 5px 38px;
      font-size: 12px;
      height: 32px;
    }
  }
}