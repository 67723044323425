$WEBPACK_ENV: dev;
@import "../alert/alert";
@import "../colors";

input:not(:focus):not(:placeholder-shown):invalid {
  border: 1px solid $primary !important;

  ~ .form__input_error {
    display: block !important;
  }
}

.label_required:before,
.form__input_label.required:before,
.form__textarea_label.required:before,
.form__categories_label.required:before,
.form__checkboxes_label.required:before,
.form__input_file_label.required:before {
  content: "* ";
  color: #AF3735;
  font-size: 20px;
  vertical-align: middle;
}

#createChatModal {
  .form__input_label.required:after {
    content: "*";
    color: #AF3735;
    font-size: 20px;
    vertical-align: middle;
  }

  .form__input_label.required:before {
    content: "";
    font-size: 0;
  }
}

.required-checkbox,
.required-radio {
  color: #AF3735;
  font-size: 20px;
}