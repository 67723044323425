$WEBPACK_ENV: dev;
@import "../../../blocks/colors";
@import "../../../abstracts/vars";

@import "--no-border";

.bootstrap-select .bs-ok-default:after {
  border-width: 0 .1em .1em 0 !important;
}

.form__input_select {
  width: 100%;

  &_error {
    border-color: $primary !important;
  }

  .dropdown-toggle {
    padding: 0;
    min-height: 24px;
    background-color: transparent;
    border: 0;
    bottom: 5px;

    &:after {
      border: 0 !important;
      content: url(#{$assetsPath}/img/common/selector-arrow.svg) !important;
      margin-top: -1rem;
      transform: rotate(0deg);
    }

    &:focus {
      outline: none !important;
    }

    .filter-option {
      .filter-option-inner-inner {
        font-size: 1rem;
      }
    }

    &.btn-light {
      &:focus {
        box-shadow: none;
      }
    }
  }

  &.show {
    .btn-light {
      &.dropdown-toggle {
        background-color: transparent;
        border: 0 !important;
        transform: rotate(0deg);

        &:active {
          background-color: transparent;
          border: 0;
        }

        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  div.dropdown-menu {
    border: 1px solid #D6DADE !important;
    border-radius: 2px !important;

    &.show {
      margin: 0;
      padding: 0;
      transform: translate3d(0px, 46px, 0px) !important;
    }
  }

  .dropdown-item {
    min-height: 38px;

    &.active {
      background-color: #F3F5F7;
      color: $black;

      &:focus:active {
        background-color: #F3F5F7;
      }
    }

    &:not(.active) {
      &:hover {
        background-color: #F3F5F7;
        color: $black;
      }
    }

    &:focus {
      outline: none;
      background-color: #F3F5F7;
      color: $black;
    }

    &:active {
      background-color: #F3F5F7;
    }
  }

  &.dropup.show {
    .dropdown-toggle {
      &:after {
        border-right: 0;
        border-left: 0;
        border-bottom: -0.5rem;
        margin-top: 0 !important;
        content: url(#{$assetsPath}/img/common/selector-arrow.svg) !important;
        transform: rotate(180deg);
      }
    }
  }

  div.inner.show {
    overflow-y: hidden !important;
  }

  &--white {
    background-color: #fff;
  }

  &--bold {
    .filter-option-inner-inner {
      font-weight: 800;
      font-family: Montserrat, sans-serif;
    }
  }

  &--no-padding {
    padding: 1rem !important;

    .dropdown-toggle:after {
      margin-top: -0.5rem !important;
    }
  }
}

div.dropdown-menu.open{
  max-height: 314px !important;
  overflow: hidden;
}
ul.dropdown-menu.inner{
  max-height: 260px !important;
  overflow-y: auto;
}