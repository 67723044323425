$WEBPACK_ENV: dev;
@import "../../abstracts/vars";

.help-text {
    display: none;
    overflow: hidden;
    width: 0;
    padding: 0 0 0 1.125rem;
    height: 1.125rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{$assetsPath}img/icons/help.svg);
}

.input-with-helper,
.bootstrap-tagsinput {
    position: relative;
    padding-right: 2.5rem !important;
}

.helper-for-input {
    display: inline-block;
    position: absolute;
    top: 1rem;
    right: 15px;
    z-index: 10;
}

.helper-for-date {
    display: inline-block;
    position: absolute;
    top: 0.95rem;
    right: 45px;
    z-index: 10;
}

.helper-for-textarea {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 15px;
    z-index: 100;
}

.helper-for-checkbox {
    display: inline-block;
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
}

.helper-for-file-dropzone {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 15px;
}

.helper-for-span {
    display: inline-block;
    position: relative;
    bottom: -0.3125rem;
    left: 5px;
}

.select-with-helper {
    ~ .btn.dropdown-toggle {
        padding-right: 30px !important;
    }
}

.helper-for-select {
    display: inline-block;
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.input-with-helper ~ .form__input_label,
.helper-for-select ~ .form__input_label {
    width: calc(100% - 3.5rem);
}