.form__input_select--no-border {
    width: auto !important;
    height: auto;
    border: 0 !important;
    padding: initial !important;

    & ~ .form__input_label {
        display: none;
    }

    .dropdown-toggle:after {
        margin-top: -1px !important;
        margin-left: .8em !important;
    }
}