@import "../colors";

.alert {
  width: 100%;
  height: auto;
  margin: .5rem 0 !important;
  font-size: 0.875rem;
  
  &__danger {
    background-color: $primaryFade;
    border: 1px solid $primary !important;
    border-radius: 1px !important;
  }

  ul {
    padding: 0;
    list-style: none;
  }
}